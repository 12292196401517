/**
 * Colors taken from read.amazon.com/notebook UI
 */
.highlight-yellow {
  border-left: 10px solid #F2E366;
}

.highlight-blue {
  border-left: 10px solid #A3C4FF;
}

.highlight-pink {
  border-left: 10px solid #FFC2F5;
}

.highlight-orange {
  border-left: 10px solid #FCB85E;
}

.highlight-note {
  border-left: 10px solid #CCCCCC;
}
